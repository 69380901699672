@use "../config/" as *;
@forward "../custom/social-icons";
@forward "../custom/progress-bar";
.waf-player {
  &.waf-component {
    @extend %p-zero;
    @extend %m-t-zero;
    @extend %m-x-half-neg;
  }
  .waf-head {
    @extend %d-none;
  }
  .card {
    &-section {
      height: calc(var(--window-inner-height) - var(--header-height));
      overflow: hidden;
      @extend %relative;
      @include flex-config(flex, column);
      @extend %relative;
      @extend %c-blue-3-bg-10;
      &::before {
        background: url('/static-assets/images/events/event-banner-bg.png?v=#{$image-version}') center / cover no-repeat;
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
      }
      .card {
        &-title {
          margin-block: 0 3rem;
          font: 3.6rem $font-sb;
        }
        &-label {
          @extend %d-none;
        }
        &-meta {
          height: 28rem;
          padding: var(--full-space) 0 0 var(--full-space);
          flex-shrink: 0;
          @extend %m-b-full;
          @extend %relative;
        }
        &-meta-list {
          width: calc(100% - var(--full-space));
        }
        &-meta-item {
          font-size: 1.6rem;
          gap: 2rem;
          @include flex-config(flex);
          &:first-child {
            .card-meta-value {
              @extend %d-block;
              @extend %truncate-line;
              .image {
                margin-right: var(--half-space);
                vertical-align: middle;
              }
            }
          }
          &:not(:last-child, :nth-last-child(2)) {
            @extend %m-b-full;
          }
          &:last-child {
            height: 100%;
            font-family: $font-sb;
            align-items: flex-end;
            gap: 0;
            opacity: .3;
            @include position(null, 0, -1rem);
            .card-meta-title {
              font-size: 1.2rem;
              text-transform: uppercase;
              writing-mode: vertical-lr;
              rotate: 180deg;
              @extend %m-b-full;
              @extend %c-white-8;
            }
            .card-meta-value {
              font-size: 22.5rem;
              line-height: 16rem;
              letter-spacing: -2.5rem;
              @extend %c-white-8;
            }
          }
        }
        &-meta-title {
          width: 6rem;
          min-width: 6rem;
          text-transform: capitalize;
          @extend %c-white-4;
        }
        &-meta-value {
          flex-grow: 1;
          gap: var(--half-space);
          @include flex-config(flex, null, null, center);
          @extend %c-white-8;
          .image {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
        &-social {
          @include position(null, 3.5rem, 2rem);
          @extend %flex-column-n-fe;
          .share-label {
            @extend %d-none;
          }
          .close {
            @extend %d-none;
          }
        }
        &-thumbnail {
          height: calc(100% - 28rem);
          margin-inline: auto;
          @extend %w-80;
          .image {
            border-radius: unset;
            display: block;
            background-color: unset;
            -webkit-filter: drop-shadow(0 8rem 10rem var(--c-orange));
            filter: drop-shadow(0 8rem 10rem var(--c-orange));
            object-fit: contain;
            object-position: center bottom;
            @extend %w-100;
            @extend %h-100;
          }
        }
      }
      .social {
        &-wrap {
          border-radius: unset;
          background-color: unset;
       }
        &-item {
          flex-direction: column;
        }
        &-item-icon {
          border: 0;
          background-color: transparent;
          &:not(:last-child) {
            @extend %m-b-full;
          }
          &[aria-expanded='true'] .social-item-favorite {
            @extend %c-orange-bg-10;
          }
          span {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            flex-direction: row-reverse;
            transition: width .3s ease-in;
            @extend %c-blue-2-bg-10;
            @extend %font-zero;
          }
          .social-item-compare {
            @extend %c-orange-bg-10;
          }
        }
        &-item-favorite::before {
          font-size: 2.2rem;
        }
        &-item-compare::before {
          font-size: 1.8rem;
        }
      }
      .social-item-compare {
        @extend %m-t-full;
        @extend %flex-c-c;
        &::before {
          font: 1.4rem/1 "waf-font-icon";
          content: map-get($map: $social, $key: "compare");
          @extend %c-pure-white-10;
        }
      }
      .fname {
        @extend %c-pure-white-6;
      }
      .lname {
        @extend %d-block;
        @extend %c-pure-white-10;
      }
    }
  }
}
.compareplayer-active {
  &::before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    backdrop-filter: blur(1rem);
    @extend %w-100;
    @extend %h-100;
    @extend %c-pure-white-bg-4;
  }
}
.compare-modal {
  width: 90%;
  border-radius: var(--full-radius);
  @extend %m-x-auto;
  &.modal {
    top: 5rem;
    bottom: 2rem;  
    background: transparent;
    @extend %d-none;
    &.active {
      display: block;
    }
  }
  .modal-head {
    position: fixed;
    top: 6rem;
    @extend %p-zero;
    .btn-close {
      .btn-text {
        @extend %c-blue-10;
        @extend %font-14-pb;
        @include flex-config(flex,row-reverse,null,center);
        &::after {
          @extend %c-blue-10;
        }
      }
    }
  }
  .modal-body {
    max-height: calc(var(--window-inner-height) - 12rem);
    border-radius: var(--full-radius);
    margin-top: 5rem;
    display: block;
    overflow-y: auto;
    @include custom-scroll;
  }
  .waf-compareplayer {
    overflow: hidden;
    @extend %m-x-zero;
    @extend %p-x-half;
    .waf-head {
      display: block;
    }
    .card-head-wrapper {
      .card-a {
        .card-thumbnail {
          margin-left: var(--half-space-negative);
        }
        .card-search {
          .form-input {
            margin-left: var(--half-space-negative);
          }
        }
      }
      .card-b {
        .card-thumbnail {
          margin-right: var(--half-space-negative);
        }
        .card-search {
          .form-input {
            width: calc(100% + var(--half-space));
            margin-right: var(--half-space-negative);
          }
        }
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-player {
    .layout-wrapper {
      max-width: unset;
      margin: 0;
    }
    .card-section {
      &::before {
        content: '';
      }
    }
  }
  .compare-modal.modal {
    top: 4rem;
    bottom: 2rem;
    padding: 0;
  }
  .compare-modal {
    overflow-y: auto;
    .modal-head {
      position: absolute;
      top: var(--two-space);
      left: var(--full-space);
      .btn-close {
        .btn-text {
          left: var(--two-space);
          color: var(--c-pure-white);
          z-index: 1;
          font-size: 0;
          &::after {
            color: var(--c-pure-white);
          }
        }
      }
      .waf-select-box {
        ::before {
          content: unset;
        }
      }
    }
    .modal-body {
      overflow-y: unset;
      max-height: calc(var(--window-inner-height) - 6rem);
      margin-top: 0;
    }
    .waf-compareplayer {
      padding: 0;
      margin-block: 0;
      .card-head-wrapper {
        .card-a {
          .card-thumbnail {
            margin-left: 0;
          }
          .card-search {
            .form-input {
              margin-left: 0;
            }
          }
        }
        .card-b {
          .card-thumbnail {
            margin-right: 0;
          }
          .card-search {
            .form-input {
              margin-right: 0;
            }
          }
        }
      }
      .card-footer {
        margin-block: 0;
        @include position(var(--two-space),var(--two-space),null,null);
        .btn-share {
          background: transparent;
          padding: 0;
          gap: 0;
          &:after {
            color: var(--c-pure-white);
          }
          .btn-text {
            font-size: 0;
          }
        }
      }
    }
    .waf-body {
      padding: var(--two-space);
    }
  }
}
@media (min-width: $desktop-min-width) {
  .waf-player {
    .card {
      &-section {
        max-width: 100%;
        height: unset;
        min-height: 45rem;
        aspect-ratio: 21/9;
        padding-inline: 5% 30%;
        display: grid;
        align-items: center;
        gap: var(--full-space);
        grid-auto-flow: column;
        grid-template-columns: 39% auto;
        .card {
          &-meta {
            height: auto;
            padding-top: 0;
            padding-left: 0;
            margin-bottom: 0;
            position: unset;
            &-list {
              width: unset;
            }
            &-item {
              gap: var(--half-space);
              &:not(:last-child, :nth-last-child(2)) {
                margin-bottom: var(--one-n-half-space);
              }
              &:last-child {
                height: 21rem;
                right: 5%;
                bottom: 7rem;
                .card-meta-title {
                  margin-bottom: 0;
                  font-size: 3rem;
                }
                .card-meta-value {
                  font-size: 30rem;
                  line-height: 21rem;
                  align-items: flex-end;
                  letter-spacing: -4rem;
                }
              }
            }
            &-title {
              width: 8rem;
              min-width: 8rem;
              font-size: 1.8rem;
            }
            &-value {
              font-size: 1.8rem;
            }
          }
          &-thumbnail {
            width: 100%;
            height: 100%;
            // margin-top: 5rem;
            .image {
              -webkit-filter: drop-shadow(0 15rem 20rem var(--c-orange));
              filter: drop-shadow(0 15rem 20rem var(--c-orange));
            }
          }
          &-title {
            width: 100%;
            margin-bottom: 7rem;
            // font-size: 6.4rem;
            font-size: 4rem;
          }
          &-social {
            top: 6rem;
            right: 4rem;
            bottom: unset;
          }
        }
        .social-item {
          align-items: flex-end;
          &-compare:hover,
          &-favorite:hover {
            width: auto;
            padding-inline: var(--three-fourth-space);
            border-radius: 50vmax;
            color: var(--c-white);
            font-size: 1.6rem;
            gap: var(--half-space);
          }
        }
        .player-bar {
          flex-grow: 1;
        }
      }
    }
  }
}
@media (min-width: $large-desktop-min-width) {
  .waf-player {
    .card-section {
      .card-meta-item:last-child {
        height: 36rem;
        .card-meta-title {
          font-size: 4rem;
        }
        .card-meta-value {
          line-height: 36rem;
          font-size: 50rem;
        }
      }
    }
  }
}